import React, { useEffect, useState } from 'react';
import { scrollToTop } from 'client-utils/scrollToTop';
import { window } from 'window-or-global';
import './ScrollToTop.scss';
import { connect } from 'react-redux';
import get from 'lodash/get';
import backToTopBtn from '../../../assets/images/button-scroll-toTop.svg';

const scrollAfterPx = 250;

const ScrollToTop = ({ isMobilePhone }) => {
  const [showButton, setShowButton] = useState(false);
  const handleScroll = () => {
    setShowButton(window.scrollY > scrollAfterPx);
  };

  useEffect(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    showButton && (
      <button
        className={`scroll-to-top-btn${isMobilePhone ? ' mobile' : ''}`}
        aria-label="Back to top"
        onClick={scrollToTop}
        onKeyDown={scrollToTop}
      >
        <img src={backToTopBtn} alt="Back to top" />
      </button>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    isMobilePhone: get(state, 'device.isMobilePhone', false),
  };
};

export default connect(mapStateToProps)(ScrollToTop);
