import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { getEcmStoreEvents } from 'ecm/actions/actions-ecmstoreevents';
import { shouldLoadPage } from 'client-utils/utilities-abtest';
import {
  getOptABTestAssignments,
  OPTIMIZELY_TEST_OPT_ID,
  SIMPLIFIED_MINI_CART,
  PANEL_TEST,
  getApplicableOptyTestIds,
} from 'shared/actions/actions-page';
import HomeApp from './app-home';

export class DumbHomeAppWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callOptimizelyAbTestService: true,
    };
  }

  UNSAFE_componentWillMount() {
    let opttIds = [];
    const aemContentTargetingOptId = this.props.aemContentTargetingOptId;
    this.props.optimizelyTestToggle && opttIds.push(OPTIMIZELY_TEST_OPT_ID);
    aemContentTargetingOptId && opttIds.push(aemContentTargetingOptId);
    this.props.simplifiedMiniCartToggle && opttIds.push(SIMPLIFIED_MINI_CART);
    this.props.panelToggle && opttIds.push(PANEL_TEST);

    const optIdList = getApplicableOptyTestIds(this.props.optABTestConfig?.default,
      this.props.optABTestConfig?.home, this.props.toggles, this.props.isDomestic);
    opttIds = [...opttIds, ...optIdList];

    if (opttIds.length > 0) {
      if (shouldLoad(this.props.abTestOptApi)) {
        this.props.getOptABTestAssignments(opttIds.join(','));
      }
    } else {
      this.setState({ callOptimizelyAbTestService: false });
    }

    if (this.props.callStoreEventsOnServer) {
      if (shouldLoad(this.props.apiStoreEvents)) {
        this.props.getEcmStoreEvents();
      }
    }
  }

  render() {
    const { abTestOptApi = {} } = this.props;
    const shouldContinue = shouldLoadPage(this.state, abTestOptApi);

    return (
      <Fragment>
        {shouldContinue
          ? <HomeApp {...this.props} />
          : null
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  callStoreEventsOnServer: state.toggles.STORE_EVENTS_SERVER,
  optimizelyTestToggle: state.toggles.OPTIMIZELY_TEST,
  abTestOptApi: state.api.abtestopt,
  apiStoreEvents: state.api.ecmstoreevents,
  simplifiedMiniCartToggle: state.toggles.SIMPLIFIED_MINI_CART,
  aemContentTargetingOptId: state.aemOptId,
  panelToggle: state.toggles.YOUR_NEIMANS_PANEL,
  optABTestConfig: get(state, 'optABTestConfig', null),
  toggles: get(state, 'toggles', null),
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
});

export default connect(mapStateToProps,
  { getOptABTestAssignments, getEcmStoreEvents })(DumbHomeAppWrapper);
