import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkedData from 'shared/components/LinkedData/linkedData';
import {
  setMetaInfo, setPageId, setPageVisited, PAGE_ID_HOME,
} from 'shared/actions/actions-page';
import get from 'lodash/get';
import EcmGet from '../EcmGet';
import {
  ECMSlots,
  ECMHOMEMETATITLE,
  ECMHOMEMETADESCRIPTION,
  ECMHOMEMETAKEYWORDS,
  ECMHOMEMETATAGS,
  ECMHOMERELLINKS,
  ECMHOMECANONICALURL,
  ECMHOMEJSONLD_NEWSEARCH,
} from '../../constants';

class Home extends Component {
  UNSAFE_componentWillMount() {
    const { locale } = this.props;
    const metaObj = {
      metaTitle: ECMHOMEMETATITLE,
      metaDescription: ECMHOMEMETADESCRIPTION,
      metaKeywords: ECMHOMEMETAKEYWORDS,
      metaTags: ECMHOMEMETATAGS,
      relLinks: ECMHOMERELLINKS,
      canonicalUrl: `${ECMHOMECANONICALURL}${get(locale, 'countryCode', '').toLowerCase() !== 'us' ? get(locale, 'localeUrl', '') : ''}`,
    };
    this.props.setMetaInfo(metaObj);
    this.props.setPageId(PAGE_ID_HOME);
    this.props.setPageVisited({
      event_name: 'pageLoad',
      page_definition_id: 'home',
      page_name: 'Homepage',
      page_template: 'home',
      page_type: 'home',
      page_version: 'test',
    });
  }

  render() {
    return (
      <main>
        <LinkedData data={ECMHOMEJSONLD_NEWSEARCH} />
        <EcmGet ecmSlot={ECMSlots.HOME_TOP} />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
  locale: state.locale,
});

const mapDispatchToProps = ({
  setMetaInfo,
  setPageId,
  setPageVisited,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
