import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import LinkedData from 'shared/components/LinkedData/linkedData';
import {
  setMetaInfo, setPageId, setPageVisited, PAGE_ID_HOME,
} from 'shared/actions/actions-page';
import get from 'lodash/get';
import window from 'window-or-global';
import RenderPopout from 'cms/components/popout/popout';
import {
  ECMHOMEMETATITLE,
  ECMHOMEMETADESCRIPTION,
  ECMHOMEMETAKEYWORDS,
  ECMHOMEMETATAGS,
  ECMHOMERELLINKS,
  ECMHOMECANONICALURL,
  ECMHOMEJSONLD_NEWSEARCH,
} from 'ecm/constants';
import { getCmsHomeEntries } from 'cms/actions';
import { detectGenderRedirectUrl, setNMConnectCookie } from 'cms/components/utils';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import RenderContent from '../renderContent';

class Home extends Component {
  componentDidMount() {
    const {
      location: { pathname },
      selectedGender,
      genderToggle,
      locale,
      queryParams,
    } = this.props;

    setNMConnectCookie(queryParams);

    const redirectUrl = detectGenderRedirectUrl(
      selectedGender,
      genderToggle,
      pathname,
      locale,
    );

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  UNSAFE_componentWillMount() {
    const {
      locale,
      selectedGender,
      genderToggle,
      brand,
    } = this.props;

    const metaTitle = selectedGender === 'M' ? 'Men’s Designer Fashion' : ECMHOMEMETATITLE;
    const metaDescription = selectedGender === 'M' ? "Shop the latest in men's designer clothing, shoes, and accessories with styles from top designers including ZEGNA, Moncler, TOM FORD, Prada & more." : ECMHOMEMETADESCRIPTION;

    const metaObj = {
      metaTitle,
      metaDescription,
      metaKeywords: ECMHOMEMETAKEYWORDS,
      metaTags: ECMHOMEMETATAGS,
      relLinks: ECMHOMERELLINKS,
      canonicalUrl: brand === 'HC' ? 'https://www.horchow.com' : `${ECMHOMECANONICALURL}${get(locale, 'countryCode', '').toLowerCase() !== 'us' ? get(locale, 'localeUrl', '') : ''}`,
    };
    this.props.setMetaInfo(metaObj);
    this.props.setPageId(PAGE_ID_HOME);
    this.props.setPageVisited({
      event_name: 'pageLoad',
      page_definition_id: 'home',
      page_name: 'Homepage',
      page_template: 'home',
      page_type: 'home',
      page_version: 'test',
    });

    let query = '/';
    if (get(locale, 'countryCode') === 'US' && genderToggle) {
      if (selectedGender === 'M') {
        query = '/mens';
      } else {
        query = '/womens';
      }
    }

    if (shouldLoad(this.props.cmsEntriesApi)) {
      this.props.dispatch(getCmsHomeEntries(query));
    }
  }

  render() {
    return (
      <main>
        <LinkedData data={ECMHOMEJSONLD_NEWSEARCH} />
        <RenderContent />
        <RenderPopout />
        <div className="cms-spacer" />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
  locale: state.locale,
  cmsEntriesApi: state.api.cms_entries,
  cmsEntries: state.cms.entries,
  selectedGender: getGenderWithOverride(state),
  genderToggle: get(state, 'toggles.HP_GENDER'),
  brand: get(state, 'brand_name.env', 'NM'),
  queryParams: get(state, 'routing.locationBeforeTransitions.query', {}),
});

const mapDispatchToProps = ({
  setMetaInfo,
  setPageId,
  setPageVisited,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
